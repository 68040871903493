import { useTranslation } from '@loveholidays/phrasebook';
import React from 'react';

import {
  useSearchSelectionChildrenCount,
  useSearchSelectionAdultsCount,
  useSearchSelectionInfantsCount,
} from '@Components/SearchForm/SearchStoreProperties';
import { MONTH_FLEXIBILITY_VALUE } from '@Constants/Flexibility';
import { useDate } from '@Dates/useDateHook';
import { useSearchAvailabilityStore, useSearchSelectionStore } from '@Stores/StoreContext';

export interface SearchSelectionDescriptionProps {
  showBoardBasis?: boolean;
  showNights?: boolean;
  showDate?: boolean;
  showDepartureAirports?: boolean;
  showFlexibility?: boolean;
}

export const SearchSelectionDescription: React.FC<SearchSelectionDescriptionProps> = ({
  showBoardBasis = false,
  showNights = false,
  showDate = false,
  showDepartureAirports = false,
  showFlexibility = false,
}) => {
  const { t } = useTranslation();
  const { localizedFormat } = useDate();
  const [boardBasis, nights, rooms, date, departureAirports, flexibility] = useSearchSelectionStore(
    (state) => [
      state.boardBasis,
      state.nights,
      state.rooms,
      state.date,
      state.departureAirports,
      state.flexibility,
    ],
  );

  const selectedDepartureAirports = useSearchAvailabilityStore((state) =>
    state.departureAirports
      .filter(({ airport }) => departureAirports.includes(airport.id))
      .map(({ airport }) => airport.name),
  );

  const adultCount = useSearchSelectionAdultsCount();
  const infantsCount = useSearchSelectionInfantsCount();
  const childrenExludingInfantsCount = useSearchSelectionChildrenCount() - infantsCount;

  let formattedDate = null;
  if (showDate && date) {
    if (flexibility === MONTH_FLEXIBILITY_VALUE) {
      formattedDate = date.format('MMMM YYYY');
    } else {
      formattedDate = localizedFormat(date);
    }
  }

  const text = [
    t('rooms.labels.room', { count: rooms.length }),
    t('rooms.labels.adultShort', { count: adultCount }),
  ];
  if (childrenExludingInfantsCount) {
    text.push(t('rooms.labels.child', { count: childrenExludingInfantsCount }));
  }
  if (infantsCount) {
    text.push(t('rooms.labels.infant', { count: infantsCount }));
  }
  if (showNights) {
    text.push(t('rooms.labels.nights', { count: nights }));
  }
  if (showDate && formattedDate) {
    const withFlex = showFlexibility && !!flexibility && flexibility !== MONTH_FLEXIBILITY_VALUE;
    text.push(
      `${formattedDate}${withFlex ? ` (${t('flexibility.label', { count: flexibility })})` : ''}`,
    );
  }
  if (showDepartureAirports && !!selectedDepartureAirports.length) {
    text.push(selectedDepartureAirports.join(', '));
  }
  if (showBoardBasis) {
    if (!boardBasis.length) {
      text.push(t('boardBasis.boardBasis', { context: 'ANY_BOARD_BASIS' }));
    }
    boardBasis.map((boardBasis) => text.push(t('boardBasis.boardBasis', { context: boardBasis })));
  }

  return (
    <div sx={{ color: 'textDefault', background: 'white', paddingRight: '4xs' }}>
      {text.join(', ')}
    </div>
  );
};

export const useSearchSelectionTextDescription: (
  props: Pick<SearchSelectionDescriptionProps, 'showNights'>,
) => string = ({ showNights = false }) => {
  const { t } = useTranslation();
  const [nights, rooms] = useSearchSelectionStore((state) => [state.nights, state.rooms]);

  const adultCount = useSearchSelectionAdultsCount();
  const infantsCount = useSearchSelectionInfantsCount();
  const childrenExludingInfantsCount = useSearchSelectionChildrenCount() - infantsCount;

  return [
    t('rooms.labels.room', { count: rooms.length }),
    t('rooms.labels.adult', { count: adultCount }),
    childrenExludingInfantsCount &&
      t('rooms.labels.child', { count: childrenExludingInfantsCount }),
    infantsCount && t('rooms.labels.infant', { count: infantsCount }),
    showNights && t('rooms.labels.nights', { count: nights }),
  ]
    .filter(Boolean)
    .join(', ');
};
